/* eslint-disable no-restricted-globals */
const dynamicCacheName = "site-dynamic-v1";
// activate event
self.addEventListener("activate", (evt) => {
  evt.waitUntil(
    caches
      .keys()
      .then((keys) =>
        Promise.all(
          keys
            .filter((key) => key !== dynamicCacheName)
            .map((key) => caches.delete(key))
        )
      )
  );
});
// fetch event
self.addEventListener("fetch", (evt) => {
  evt.respondWith(
    caches.match(evt.request).then(
      (cacheRes) =>
        cacheRes ||
        fetch(evt.request).then((fetchRes) =>
          caches.open(dynamicCacheName).then((cache) => {
            cache.put(evt.request.url, fetchRes.clone());
            return fetchRes;
          })
        )
    )
  );
});
